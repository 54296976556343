import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/projectsTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "2017-10-01-opsdroid.md"
      }}>{`Opsdroid`}</a>{` is a chatbot framework powered by different kinds of skills - which are basically python functions that interact with Opsdroid.
The skill - `}<em parentName="p">{`words`}</em>{` - takes advantage of the NLTK library to do things with words such as:`}</p>
    <ul>
      <li parentName="ul">{`Providing help by unscrambling letters in a scrabble game`}</li>
      <li parentName="ul">{`Providing definitions for a word`}</li>
      <li parentName="ul">{`Translating a word from a language into another language`}</li>
    </ul>
    <p>{`Each function of the skill uses regex and groups to trigger the event and give meaning to what the user wishes to do.`}</p>
    <h1 {...{
      "id": "the-good",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#the-good",
        "aria-label": "the good permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Good`}</h1>
    <ul>
      <li parentName="ul">{`The scrabble helper only returns words that are at least 4 characters long`}</li>
      <li parentName="ul">{`If there is a long list of words that the user can form with the letters, 5 random words from that list are suggested`}</li>
      <li parentName="ul">{`The dictionary function gives a good basic information about a word`}</li>
      <li parentName="ul">{`Translating a word from English to another language works well`}</li>
    </ul>
    <h1 {...{
      "id": "the-not-so-good",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#the-not-so-good",
        "aria-label": "the not so good permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Not So Good`}</h1>
    <ul>
      <li parentName="ul">{`When the user triggers a function it takes between 1-3 seconds for results to appear`}</li>
      <li parentName="ul">{`Translating a word from a language other than English might not return any results`}</li>
      <li parentName="ul">{`The dictionary function doesn't show all the definitions of a word`}</li>
      <li parentName="ul">{`The dictionary examples come up as empty sometimes`}</li>
      <li parentName="ul">{`The synonyms list in the dictionary doesn't really show up synonyms`}</li>
    </ul>
    <h1 {...{
      "id": "how-it-all-came-to-be",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#how-it-all-came-to-be",
        "aria-label": "how it all came to be permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How it all came to be`}</h1>
    <p>{`By doing the `}<em parentName="p">{`100 Days Of Code`}</em>{` challenge and working on the challenges on Pybites.
On challenge 5, we need to compare two twitter accounts and get the similarity index from them, after a quick research I figured it out that I could use NLTK to solve the issue. I spent a few days reading the documentation and working the examples.
That's when I thought that perhaps we could implement these things in opsdroid. I started working on the idea and the skill - `}<em parentName="p">{`words`}</em>{` came to be.`}</p>
    <p>{`There are still plenty of things that can be improved and I have plans to fix the issues in order to make this skill even better and more pleasant to use.`}</p>
    <h1 {...{
      "id": "info",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#info",
        "aria-label": "info permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Info`}</h1>
    <p><strong parentName="p">{`GitHub repo:`}</strong>{` `}<a parentName="p" {...{
        "href": "https://github.com/opsdroid/skill-words"
      }}>{`https://github.com/opsdroid/skill-words`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      